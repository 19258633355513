//import { requestAPI } from "../assets/jsx/general.jsx";
import Moment from "moment";
import { type } from "os";
export function getProrateValue(pTotalAmt, pNewAmout, pItemAmount) {
  var pValue = 0;
  pValue = pNewAmout * pItemAmount;
  pValue = pValue / pTotalAmt;

  return pValue;
}
export function AppSetting(appKey)
{
  try {
    var sett = JSON.parse(localStorage.getItem("lstSetting")).find(x => x.Key === appKey)
    if (sett != undefined && sett.value != null && sett.value != "") {
      return sett.value;
    }
  } catch (e) {

  }
  return "";
}
export function getSetting(settings,appKey) {
  try {
    var sett = settings.find(x => x.Key === appKey)
    if (sett != undefined && sett.value != null && sett.value != "") {
      return sett.value;
    }
  } catch (e) {

  }
  return "";
}

export function convertDateFormate(dateFormate) {
  try {
    dateFormate = dateFormate.replaceAll("/", "-");
    return dateFormate;

  } catch (e) {

  }
  return "";
}

//function getAppSetting() {
//  requestAPI("api/AppSetting", 'GET')
//    .then(data => {
//      if (data.response.Status == "Success") {
//        localStorage.setItem("lstSetting", JSON.stringify(data.response.DataObject));
//      }
//    }).catch(function (error) {
//      console.log(error);
//    });
//}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
 
export function PassFromToDateValidation(frm, to, req, appSetting) {
  if (frm == undefined || frm == "" || frm == "Invalid date") {
    if (req == undefined || req == false)
       return "";
    return "Please Select From Date";
  } 
  if (to == undefined || to == "" || to == "Invalid date") {
    if (req == undefined || req == false)
      return "";
    return "Please Select To Date";
  }
  if (frm == to) {
    return "";
  }
  var frmDate = Moment(frm, getSetting( appSetting, "DateFormate").toUpperCase());
  var toDate = Moment(to, getSetting( appSetting, "DateFormate").toUpperCase());
  if (frmDate.isBefore(toDate) == false) {
    return "Please select Valid From and To date. To date can't be less than From Date";
  }

  return "";
}

export function printRpt(body) {
  var mywindow = window.open('', 'PRINT', 'height=800,width=1040');
  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write('</head><body >');
  mywindow.document.write(body);
  mywindow.document.write('</body></html>');
  mywindow.document.close();
  mywindow.focus();
  mywindow.print();
  mywindow.close();
}

export function controlValidate(name) {
    try {
        var roleMethod = JSON.parse(localStorage.getItem("roleMethod"));
        if (roleMethod != undefined && roleMethod != null) {
            var visible = roleMethod.find(x => x.description == name);
            if (visible) {
                return true;
            }
            else {
                return false;
            }
        }
    } catch (e) {

    }
    return false;
}
