import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { Disc, X, Circle } from "react-feather"
import classnames from "classnames"
import Navbar from "../../navbar/Navbar";
import { requestAPI } from "../../../../assets/jsx/general.jsx";
import { getSetting } from "../../../../assets/CommonCal.js"

var logo = "";
var appSetting = [];
class SidebarHeader extends Component {

  async getAppSetting() {
    await requestAPI("api/AppSetting", 'GET')
      .then(data => {
        if (data.response.Status == "Success") {
          this.appSetting = data.response.DataObject;
          this.logo = getSetting(this.appSetting, "reportLogo");
        }
      }).catch(function (error) {
        console.log(error);
      });
  }
componentDidMount(){
  this.getAppSetting();
}
render() {

  let {
    toggleSidebarMenu,
    activeTheme,
    collapsed,
    toggle,
    sidebarVisibility,
    menuShadow
  } = this.props
  return (
    <div>
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <div className="navbar-brand" onClick={() => {
              toggleSidebarMenu(false)
              toggle()
            }}>
              <div className="brand-logo d-none" style={{ backgroundImage: `url(${this.logo})` }} />
              <h2 className="brand-text mb-0">POS <span style={{ fontSize: '12px', color: 'black' }}>(20210306)</span></h2>
            </div>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true)
                    toggle()
                  }}
                  className={classnames(
                    "toggle-icon icon-x d-none d-xl-block font-medium-4",
                    {
                      "text-primary": activeTheme === "primary",
                      "text-success": activeTheme === "success",
                      "text-danger": activeTheme === "danger",
                      "text-info": activeTheme === "info",
                      "text-warning": activeTheme === "warning",
                      "text-dark": activeTheme === "dark"
                    }
                  )}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                  <Circle
                    onClick={() => {
                      toggleSidebarMenu(false)
                      toggle()
                    }}
                    className={classnames(
                      "toggle-icon icon-x d-none d-xl-block font-medium-4",
                      {
                        "text-primary": activeTheme === "primary",
                        "text-success": activeTheme === "success",
                        "text-danger": activeTheme === "danger",
                        "text-info": activeTheme === "info",
                        "text-warning": activeTheme === "warning",
                        "text-dark": activeTheme === "dark"
                      }
                    )}
                    size={20}
                  />
                )}
              <X
                onClick={sidebarVisibility}
                className={classnames(
                  "toggle-icon icon-x d-block d-xl-none font-medium-4",
                  {
                    "text-primary": activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark"
                  }
                )}
                size={20}
              />
            </div>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
      <Navbar />

    </div>
  )
}
}

export default SidebarHeader
