import * as firebase from "firebase/app"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"
import { postRequestAPIWithOutToken, requestAPI } from "../../../assets/jsx/general.jsx";
import axios from "axios"
import $ from "jquery";
import { config } from "../../../authServices/firebase/firebaseConfig"

// Init firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

let firebaseAuth = firebase.auth;

// const initAuth0 = new auth0.WebAuth(configAuth)

export const submitLoginWithFireBase = (email, password, remember) => {
  return dispatch => {
    let userEmail = null,
      loggedIn = false
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        firebaseAuth.onAuthStateChanged(user => {
          result.user.updateProfile({
            displayName: "Admin"
          })
          let name = result.user.displayName
          if (user) {
            userEmail = user.email
            loggedIn = true
            dispatch({
              type: "LOGIN_WITH_EMAIL",
              payload: {
                email: userEmail,
                name,
                isSignedIn: loggedIn,
                loggedInWith: "firebase"
              }
            })
          }
          if (user && remember) {
            firebase
              .auth()
              .setPersistence(firebase.auth.Auth.Persistence.SESSION)
              .then(() => {
                dispatch({
                  type: "LOGIN_WITH_EMAIL",
                  payload: {
                    email: userEmail,
                    name,
                    isSignedIn: loggedIn,
                    remember: true,
                    loggedInWith: "firebase"
                  }
                })
              })
          }
          history.push("/")
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithFB = () => {
  return dispatch => {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.setCustomParameters({
      display: "popup"
    })
    firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        let token = result.credential.accessToken,
          // The signed-in user info.
          user = result.user.email
        dispatch({
          type: "LOGIN_WITH_FB",
          payload: {
            user,
            token,
            loggedInWith: "firebase"
          }
        })
        if (user) history.push("/")
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const loginWithTwitter = () => {
  return dispatch => {
    let provider = new firebase.auth.TwitterAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function(result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_TWITTER",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}

export const loginWithGoogle = () => {
  return dispatch => {
    let provider = new firebase.auth.GoogleAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function(result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.user.displayName,
          photoUrl = result.user.photoURL
        dispatch({
          type: "LOGIN_WITH_GOOGLE",
          payload: {
            email: user,
            name: name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}

export const loginWithGithub = () => {
  return dispatch => {
    let provider = new firebase.auth.GithubAuthProvider()
    firebaseAuth
      .signInWithPopup(provider)
      .then(function(result) {
        let token = result.credential.accessToken,
          user = result.user.email,
          name = result.additionalUserInfo.username,
          photoUrl = result.user.photoURL

        dispatch({
          type: "LOGIN_WITH_GITHUB",
          payload: {
            user,
            name,
            photoUrl,
            token,
            loggedInWith: "firebase"
          }
        })
        history.push("/")
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}

export const loginWithJWT = user => {
  return dispatch => {
    $("#spinner").css("display", "inline-block");
    var method = "POST";
    var url = "api/Login";
    var param = {
      email: user.email,
      password: user.password
    };
    postRequestAPIWithOutToken(url, method, param)
      .then(d => {
        var response = d.response;
        if (response != undefined) {
          if (response.Code == 2) { // 2 means unathorized user
            $("#error").css("display", "block");
            $("#spinner").css("display", "none");
          }
          //else if (response.DataObject[1].userRole == "Customer") {
          //  $("#error").css("display", "block");
          //  $("#spinner").css("display", "none");
          //}
          else {
            var loggedInUser = response.DataObject[1];
            dispatch({
              type: "LOGIN_WITH_JWT",
              payload: { loggedInUser, loggedInWith: "jwt" }
            })
            localStorage.setItem('token', response.DataObject[1].token);
            localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
            localStorage.setItem('loggedInWith', "jwt");
            localStorage.setItem('fullName', response.DataObject[1].firstName + " " + response.DataObject[1].lastName);
            localStorage.setItem('email', response.DataObject[1].email);
            localStorage.setItem('accountid', response.DataObject[1].accountId);
            var menuRights = response.DataObject[0].response.DataObject;
            localStorage.setItem('subMenuRights', JSON.stringify(menuRights));
            var navBar = response.DataObject[2].response.DataObject;
              localStorage.setItem('navbar', JSON.stringify(navBar));
              var roleMethod = response.DataObject[3].response.DataObject;
              localStorage.setItem("roleMethod", JSON.stringify(roleMethod));
            window.location.href = '/';
            //window.location.href = '#/views/UserProfile';
          }
        }
        else {
          dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: true })
        }
      });
  }
    }

export const logoutWithJWT = () => {
  return dispatch => {
    localStorage.setItem('token', "");
    localStorage.setItem('loggedInUser', null);
    localStorage.setItem('fullName', "");
    localStorage.setItem('email', "");
    localStorage.setItem('accountid', "");
    localStorage.setItem('navbar', null);
    localStorage.setItem('subMenuRights', null);
    localStorage.setItem('lstSetting', null); 
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    window.location.href = "/#/pages/login";
  }
}

export const logoutWithFirebase = user => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
    window.location.href = "/#/pages/login";
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
