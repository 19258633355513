
var enviromentUrl = require('../../assets/serverUrl.json');
// function that verifies if a string has a given length or not
const verifyLength = (value, length) => { 
  if (value != undefined) {
    if (value.length >= length) {
        return true;
      }
    }
    return false;
  }

const IsNumberExceed = (value, length) => {
  if (value.length > length || value.length==0) {
    return true;
  }
  return false;
}
// function that verifies if two strings are equal
const compare = (string1, string2) => {
    if (string1 === string2) {
        return true;
    }
    return false;
}
// function that verifies if value contains only numbers
const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9-]+$");
    if (numberRex.test(value)) {
        return true;
    }
    return false;
}


// verifies if value is a valid URL
const verifyUrl = (value) => {
    try {
        new URL(value);
        return true;
    } catch (_) {
        return false;
    }
}

const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
        return true;
    }
    return false;
}

const BaseURL = enviromentUrl.server_Api_Url.url_server; 
var url = "";
const requestAPI = (url, method) => {
    url = BaseURL + url;
    var token = localStorage.getItem('token');
    return fetch(url, {
        method: method, 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(handleErrors)
      .then(

            response => response.json()
    )
      .then(
          
            response => response
        )
      .catch(error => {
            return error;
        })
}

function handleErrors(response) {
  
 
  if (response.status == 401 || response.statusText == "Unauthorized") {
   // localStorage.setItem('token', "");
   //localStorage.setItem('loggedInUser', null);
   // localStorage.setItem('fullName', "");
   // localStorage.setItem('email', "");
   // localStorage.setItem('accountid', "");
   // localStorage.setItem('navbar', null);
   // localStorage.setItem('subMenuRights', null);
   // localStorage.setItem('lstSetting', null);
    window.location.href = "/#/misc/not-authorized";
   // return response;
  }
  else {
  }
  return response;
}

const postRequestAPI = (url, method, data) => {
    
    url = BaseURL + url;
    var token = localStorage.getItem('token');
    return fetch(url, {
        method: method,
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(handleErrors)
        .then(response => response.json())
        .then(response => response)
      .catch(error => { 
            return error;
        })
}

const postRequestAPIWithOutToken = (url, method, data) => {
    url = BaseURL + url;
    return fetch(url, {
        method: method,
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'WithOutBearer: withoutAuthentication'
        },
        body: JSON.stringify(data)
    }).then(response => { return response.json() })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });
}


const postTokenAPI = (url, method, data) => {

    url = BaseURL + url;
    var token = localStorage.getItem('token');
    return fetch(url, {
        method: method,
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(handleErrors)
        .then(response => response.json())
        .then(response => response)
        .catch(error => {
            return error;
        })
}
const postFormAPI = (url, method, formData) => {

    url = BaseURL + url;
   
    var token = localStorage.getItem('token');
    return fetch(url, {
        method: method,
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'contentType': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        },
        body: formData,
    }).then(handleErrors)
        .then(response => response.json())
        .then(response => response)
        .catch(error => {
            return error;
        })
}


const tokenRequestAPI = (url, method, data) => {
    url = BaseURL + url;
    var token = localStorage.getItem('token');
    return fetch(url, {
        method: method,
        withCredentials: true, 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    }).then(handleErrors).then(response => response.json())
        .then(response => response)
      .catch(error => { 
            return error;
        })
}

const updateStateValues = (state, sorted) => {

  var sorting = localStorage.getItem('IsSorting');

  const newState = { ...state };
  const storedSortingValue = JSON.parse(localStorage.getItem('sortingValue'));

  newState.pageIndex = state.page;

  if (state.filtered[0]) {
    newState.searchText = state.filtered[0].value;
  } else {
    newState.searchText = '';
  }

  if (storedSortingValue) {
    newState.sortColumn = storedSortingValue.sortColumn;
    newState.sortOrder = storedSortingValue.sortOrder;
    // set sorting to true if sorting value exists in local storage
    sorting = true;
  } else if (sorted && sorted.length > 0) {
    newState.sortColumn = sorted[0].id;
    newState.sortOrder = sorted[0].desc ? 'desc' : 'asc';
  } else {
    newState.sortColumn = '';
    newState.sortOrder = '';
  }

  newState.page = state.page + 1;
  newState.pageSize = state.pageSize;
  newState.loaded = false;

  return newState;
};

export {
  verifyLength,
  IsNumberExceed,
    compare,
    verifyNumber,
    verifyUrl,
    verifyEmail,
    requestAPI,
    postRequestAPI,
    postTokenAPI,
    tokenRequestAPI,
    postFormAPI,
    postRequestAPIWithOutToken,
    updateStateValues
};


