import React from "react"
import * as Icon from "react-feather"
import { requestAPI } from "../assets/jsx/general.jsx";
import $ from "jquery";
import { history } from "../history"
let nav = [];
let tempChildern = [];
var objNav = new Object();
var navbarchild = new Object();
var navbar = localStorage.getItem("navbar");
navbar = JSON.parse(navbar);
if (navbar != null) {
  for (var i = 0; i < navbar.length; i++) {
    if (navbar[i].isVisible == true) {
      objNav = new Object();
      objNav.id = navbar[i].id;
      objNav.title = navbar[i].title;
      objNav.type = navbar[i].type;
      objNav.navLink = navbar[i].navLink;
      var icon = navbar[i].icon
      objNav.icon = icon;
      objNav.badge = "warning";
      var badgeText;
      if (navbar[i].children.length > 0) {
        badgeText = navbar[i].children.length;
      }
      else {
        badgeText = "";
      }
      objNav.badgeText = badgeText ;
      tempChildern = [];
      var child = navbar[i].children;
      for (var x = 0; x < child.length; x++) {
        navbarchild = new Object();
        navbarchild.id = child[x].id;
        navbarchild.title = child[x].title;
        navbarchild.type = "item";
        navbarchild.icon = child[x].icon;
        //navbarchild.permissions = ["admin", "employee"];
        navbarchild.navLink = child[x].navLink;
        tempChildern.push(navbarchild);
      }
      objNav.children = tempChildern;
      nav.push(objNav);
    }
  }
}
else {
  window.location.href = "/#/pages/login";
}

const navigationConfig = nav;
export default navigationConfig
