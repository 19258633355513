import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import { Link } from "react-router-dom"
import * as Icon from "react-feather"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"


const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right>
      <Link to="/">
        <DropdownItem style={{ width: '100%' }}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">User Profile</span>
        <span ></span>
        </DropdownItem>
      </Link>
      <Link to={"/views/EditProfile/" + localStorage.getItem("accountid")} >
        <DropdownItem style={{width:'100%'}}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
        
      </DropdownItem>
      </Link>
      <Link to="/views/ChangePassword">
        <DropdownItem style={{ width: '100%' }}
          >
        <Icon.Activity size={14} className="mr-50" />
        <span className="align-middle">PassWord</span>
       
        </DropdownItem>
      </Link>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={e => {
          e.preventDefault()
          if (isAuthenticated) {
            localStorage.setItem('token', "");
            localStorage.setItem('loggedInUser', null);
            localStorage.setItem('fullName', "");
            localStorage.setItem('email', "");
            localStorage.setItem('accountid', "");
            localStorage.setItem('navbar', null);
            localStorage.setItem('subMenuRights', null);
            localStorage.setItem('lstSetting', null); 
            window.location.href = "/#/pages/login";
          } else {
            const provider = localStorage.getItem('loggedInWith');
            if (provider !== null) {
              if (provider === "jwt") {
                localStorage.setItem('token', "");
                localStorage.setItem('loggedInUser', null);
                localStorage.setItem('fullName', "");
                localStorage.setItem('email', "");
                localStorage.setItem('accountid', "");
                localStorage.setItem('navbar', null);
                localStorage.setItem('subMenuRights', null);
                localStorage.setItem('lstSetting', null); 
                window.location.href = "/#/pages/login"; 
              }
              if (provider === "firebase") {
                return props.logoutWithFirebase()
              }
            } else {
              window.location.href = "/#/pages/login";
            }
          }

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-left">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
            <div className="user-nav d-sm-flex d-none">
            
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div>
           
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
